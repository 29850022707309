// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-map-page-covid-js": () => import("./../src/templates/MapPageCOVID.js" /* webpackChunkName: "component---src-templates-map-page-covid-js" */),
  "component---src-templates-map-page-js": () => import("./../src/templates/MapPage.js" /* webpackChunkName: "component---src-templates-map-page-js" */),
  "component---src-templates-blog-index-js": () => import("./../src/templates/BlogIndex.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-single-post-js": () => import("./../src/templates/SinglePost.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

